import * as React from 'react';
import { Link } from 'gatsby';
import { useSiteImage } from '../hooks/use-site-image';
import { useSiteMetadata } from '../hooks/use-site-metadata';

import Layout from '../components/homepage-layout.js';

function IndexPage() {
  const { siteImage } = useSiteImage();
  const { siteMetadata } = useSiteMetadata();

  return (
    <Layout
      title="Engineering excellence"
      description="Motivation behind my site and vision for the future."
      image={siteImage}
      canonicalURL={siteMetadata.siteUrl}
    >
      <div className="px-4 pb-10 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block">Carefully curated for</span>
            <span className="block text-indigo-600">
              engineers and tech leads
            </span>
          </h1>
          <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            <span className="text-yellow-300">⚠️</span> I have moved
            my blog to Medium.com. <br />
            You can find future articles at:{' '}
            <a
              className="text-blue-500"
              href="https://mihaibojin.medium.com"
            >
              mihaibojin.medium.com
            </a>
          </p>
        </div>
        <main>
          <div className="relative px-4 mt-12 sm:px-6 lg:px-8">
            <div className="mx-auto text-lg max-w-prose">
              <h1>
                <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                  About me
                </span>
              </h1>
            </div>
            <div className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
              <p>
                {' '}
                I discovered computers at an early age, first enjoying
                games such as{' '}
                <a href="https://en.wikipedia.org/wiki/Prince_of_Persia">
                  Prince of Persia
                </a>{' '}
                and <em>Formula 1</em>, and later learning{' '}
                <a href="https://en.wikipedia.org/wiki/Clipper_(programming_language)">
                  Clipper
                </a>{' '}
                and writing my first app - a personal savings database
                - when I was 12.
              </p>

              <p>
                In high school, I took an informatics-intensive course
                where I learnt a lot of programming basics, maths,
                algorithms, and such. To the day I credit this course
                and <em>one amazing teacher</em> I had with many of my
                later successes. Faucibus commodo massa rhoncus,
                volutpat.{' '}
              </p>
              <p>
                I went to University POLITEHNICA Bucharest and studied
                Informatics Engineering and then did a Masters' in
                Project Management at the Academy of Economic Studies
                Bucharest and later on did a second Masters in
                Management of Information Systems at Trinity College
                Dublin.
              </p>
              <p>
                For the first 9 years of my career I mostly did web
                development in the LAMP-stack, using various Linux
                distros, Apache (and later Nginx), PHP, MySQL, and
                frontend web development with JQuery, HTML, and CSS. I
                was also a hands-on manager for a year and a half, but
                later decided I wasn't ready to give up coding and
                transitioned to an{' '}
                <Link to="/ic" title="Individual Contributors">
                  Individual Contributor
                </Link>{' '}
                role.
              </p>
              <p>
                I then transitioned to JAVA, taking a role in
                Salesforce Production Support, working with systems of
                scale and specializing on the Apex programming
                language and related sub-systems of the Salesforce
                core app. Later on, I transitioned to a programming
                team and build tools to aid other Production Support
                engineers in investigating performance degradations
                and doing proactive monitoring (e.g.,{' '}
                <a href="https://github.com/salesforce/pyplyn">
                  Pyplyn
                </a>
                ).
              </p>
              <p>
                In 2018 I joined MongoDB as a Senior Software Engineer
                and in 2019 I was promoted to a Lead Engineer role,
                effectively transitioning into a people leadership
                role for the second time in my career.
              </p>
              <p>This is where I am today...</p>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
}

export default IndexPage;
